import request from '@/utils/request'

export function article(params) {
  return request({
    url: '/official/api/article/page',
    method: 'get',
    params
  })
}

export function articlePage(uuid) {
  return request({
    url: `/official/api/article/uuid/${uuid}`,
    method: 'get'
  })
}

export function insuranceProducts(params) {
  return request({
    url: '/official/api/insuranceProducts/page',
    method: 'get',
    params
  })
}

export function cooperativeInstitutions(params) {
  return request({
    url: '/official/api/cooperativeInstitutions/page',
    method: 'get',
    params
  })
}


export function conPanylist(params) {
  return request({
    url: '/api/insuranceOrg/list',
    method: 'get',
    params
  })
}

export function insurance(params) {
  return request({
    url: '/api/insurance/page',
    method: 'get',
    params
  })
}

export function insureDetail(params) {
  return request({
    url: '/api/insurance/detail',
    method: 'get',
    params
  })
}

export function dict(dictName) {
  const params = {
    dictName,
    page: 0,
    size: 9999
  }
  return request({
    url: '/api/dictDetail',
    method: 'get',
    params
  })
}

export default { article, articlePage, insuranceProducts, cooperativeInstitutions, conPanylist, insurance, insureDetail, dict }
